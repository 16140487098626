<template>
  <div>
    <van-action-sheet
      :value="editBudgetShow"
      :title="`修改${budgetType.desc}`"
      @cancel="onCancel"
      @click-overlay="onCancel"
    >
      <van-form>
        <van-field :value="name" name="name" :label="`广告${level == 'campaign' ? '系列' : '组'}名称`" readonly />
        <van-field :value="budget + '  ' + budgetType.desc" name="budget" :label="budgetType.desc" readonly />
        <van-field
          v-model="newValue"
          type="number"
          name="newBudget"
          :label="`修改${budgetType.desc}`"
          :placeholder="budgetType.desc"
          :rules="[{ required: true, message: `请填写${budgetType.desc}` }]"
          @input="handleInput"
        />
        <div style="margin: 16px">
          <van-button round block type="info" @click="submitBudget" id="editBudget">提交</van-button>
        </div>
      </van-form>
    </van-action-sheet>
  </div>
</template>
<script>
import { mutateBudget } from '@/api/campaign';
import { Notify, Dialog } from 'vant';
export default {
  props: {
    editBudgetShow: {
      type: Boolean,
      default: () => {},
    },
    data: {
      type: Object,
      default: () => {},
    },
    level: {
      type: String,
      default: 'campaign',
    },
    type: {
      type: String,
      default: 'budget',
    },
  },
  computed: {
    //处理输入框两位小数
    handleInput() {
      let value = this.newValue;
      console.log(value, this.newValue);
      if (value) {
        if (value === '.') {
          this.newValue = '0.'; // 小数点前加个零
          return;
        }
        // 去除除数字外、多余的小数点和零、以及限制小数点后最多两位的非法字符
        this.newValue = value
          .replace(/[^\d.]/g, '')
          .replace(/^\./g, '')
          .replace(/\.{2,}/g, '.')
          .replace('.', '$#$')
          .replace(/\./g, '')
          .replace('$#$', '.')
          .replace(/^(-)*(\d+)\.(\d\d).*$/, '$1$2.$3');
        // 如果第一位是零，只有可能接下来是小数点.
        if (value !== '' && value.substr(0, 1) === '0' && value.length > 1) {
          if (value.substr(1, 1) !== '.') {
            this.newValue = '';
            return false;
          }
        }
      }
    },
    name() {
      if (this.level == 'campaign') {
        return this.data.ad.campaignName;
      } else {
        return this.data.ad.adGroupName;
      }
    },
    budget() {
      if (this.level == 'campaign') {
        if (this.type == 'budget') {
          return this.data.ad.campaignLifetimeBudget || this.data.ad.campaignDailyBudget;
        } else if (this.type == 'campaignTargetCpa') {
          return this.data.ad.campaignTargetCpa;
        }
      } else {
        if (this.type == 'budget') {
          return this.data.ad.adGroupLifetimeBudget || this.data.ad.adGroupDailyBudget;
        } else {
          return this.data.ad.adGroupBidAmount;
        }
      }
      return '';
    },
    budgetType() {
      if (this.type == 'budget') {
        if (
          (this.level == 'campaign' && this.data.ad.campaignLifetimeBudget) ||
          (this.level == 'adset' && this.data.ad.adGroupLifetimeBudget)
        ) {
          return { desc: '总预算', type: 'lifetimeBudget' };
        } else {
          return { desc: '日预算', type: 'dailyBudget' };
        }
      } else if (this.type == 'bidAmount') {
        return { desc: '出价', type: 'bidAmount' };
      } else if (this.type == 'campaignTargetCpa') {
        return { desc: '目标费用', type: 'campaignTargetCpa' };
      }
      return '';
    },
    setValKey() {
      if (this.budgetType.type == 'lifetimeBudget') {
        if (this.level == 'campaign') {
          return 'campaignLifetimeBudget';
        } else {
          return 'adGroupLifetimeBudget';
        }
      } else if (this.budgetType.type == 'dailyBudget') {
        if (this.level == 'campaign') {
          return 'campaignDailyBudget';
        } else {
          return 'adGroupDailyBudget';
        }
      } else if (this.budgetType.type == 'bidAmount') {
        return 'adGroupBidAmount';
      } else if (this.budgetType.type == 'campaignTargetCpa') {
        return 'campaignTargetCpa';
      }
      return '';
    },
  },
  data() {
    return {
      newValue: null,
    };
  },
  methods: {
    onCancel() {
      this.newValue = '';
      this.$emit('update:editBudgetShow', false);
    },
    submitBudget() {
      let params = {
        platform: this.data.ad.platform,
        level: this.level,
        accountId: this.data.ad.adAccountId,
        campaignId: this.data.ad.campaignId,
        adsetId: this.data.ad.adGroupId,
        credentialId: this.data.ad.credentialId,
      };
      params[this.budgetType.type] = this.newValue;
      Dialog.confirm({
        title: '确认',
        message: `确定修改${this.budgetType.desc}为${this.newValue}`,
      })
        .then(() => {
          // on confirm
          this.$showLoading();
          mutateBudget(params).then(
            (res) => {
              this.$hideLoading();
              console.log(res);
              if (res.code == 0) {
                Notify({ type: 'success', message: '修改成功' });
                this.$emit('setListItem', this.setValKey, this.newValue);
                this.onCancel();
              } else {
                // Notify({ type: "danger", message: res.comment });
              }
            },
            (err) => {
              this.$hideLoading();
              console.log(err);
            },
          );
        })
        .catch(() => {
          this.onCancel();
        });
    },
  },
};
</script>
<style lang="scss" scoped></style>
