<template>
  <div class="bar">
    <div class="top-bar">
      <div>
        <van-dropdown-menu>
          <van-dropdown-item v-model="currency" :options="currencyList"  />
        </van-dropdown-menu>
      </div>
      <div>
        <van-dropdown-menu>
          <van-dropdown-item v-model="sort" :options="sortParamsList"/>
        </van-dropdown-menu>
      </div>
      <div>
        <van-dropdown-menu>
          <van-dropdown-item :value="time" :options="timeList" @change="changeTime" ></van-dropdown-item>
          <!-- <van-dropdown-item v-for="item in timeList" :key="item.value">{{item.text}}</van-dropdown-item> -->
        </van-dropdown-menu>
      </div>
      <div class="dateRange">
        <van-cell title="选择日期区间" :value="date" @click="show = true" />
        <van-calendar v-model="show" type="range" @confirm="onConfirm($event,false,true)" :default-date="selectTime" :allow-same-day="true" :min-date="new Date('2020-01-01')" :max-date="new Date()" />
      </div>
      <div v-if="params.syncData" class="btn">
        <van-button type="info" size="small" icon="replay" @click="syncData">同步数据</van-button>
      </div>
    </div>
    <!-- <syncData :syncDataShow.sync="syncDataShow"></syncData> -->
  </div>
</template>
<script>
// import syncData from './syncData.vue'
import globalData from '@/minixs/data.js'
export default {
  mixins:[globalData],
  props: {
      params:{
          type:Object,
          default:()=>({})
      },
      dateRange:{
        type:Array,
        default:()=>[]
      }
  },
  // components:{
  //     syncData
  // },
  computed:{
        currency:{
            get(){
                return this.params.currency
            },
            set(v){
                this.$emit('setParams','currency',v)
            }
        },
        sort:{
          get(){
            return this.params.sort
          },
          set(v){
            this.$emit('setParams','sort',v)
          }
        },
        time:{
            get(){
              return this.params.time
            },
            set(v){
              if(this.finish){
                this.$emit('setParams','time',v,this.timeRange)
              }
              
            }
        },
        // currencyList(){
        //     return this.params.currencyList || []
        // },
        timeList(){
            return this.dateList 
        },
        selectTime(){
          if(this.time){
            let date = this.dateList.filter(v=>v.value == this.time)[0];
            return [date.start(),date.end()]
          }else if(this.dateRange && this.dateRange.length){
            return this.dateRange
          }else{
            return null
          }
        },

  },
  data() {
    return {
        syncDataShow:false,
        show:false,
        date:'',
        timeRange:null,
        finish:false
    };
  },
  mounted(){
    this.changeTime(this.time)
  },
  methods:{
      syncData(){
        this.$router.push({name:'creatSyncJob',query:{time:this.time}})
      },
      changeSort(v){
        console.log(v);
      },
      changeTime(k){
        if(k){
          let date = this.dateList.filter(v=>v.value == k)[0];
          this.finish = true;
          this.time = k;
          this.onConfirm([date.start(),date.end()],k);
          // this.$emit('setParams','time',k,[])
        }else if(this.dateRange && this.dateRange.length){
          this.onConfirm(this.dateRange);
        }else{
          this.date = '自定义'
        }
        
      },
      formatDate1(date) {
        return `${date.getMonth() + 1}/${date.getDate()}`;
      },
      setTime(){

      },
      onConfirm(date,k){
        const [start, end] = date;
        this.timeRange = [start, end];
        let flag = false;
        this.dateList.filter(v=>{
          if(v.value){
            if((v.start().getTime() == start.getTime()) && (v.end().getTime() == end.getTime())){
              flag = true;
              if(!k){
                this.time = v.value;
                // this.finish = false;
              }
              
            }
          }
        })
        if(!flag ){
          this.time = k || null;
        }
        this.date = `${this.formatDate1(start)} - ${this.formatDate1(end)}`;
        this.show = false;
      }
  }
};
</script>
<style lang="scss">
.bar {
  .top-bar {
    display: flex;
    width: 100%;
    justify-content: space-between;
    .van-dropdown-menu__bar {
      box-shadow: none;
    }
     .van-dropdown-menu__title{
        font-size: 13px;
      }
    .btn{
        margin-top: 16px;
    }
    .dateRange{
      .van-cell__title{
        display: none;
      }
    }
    
  }
}
</style>