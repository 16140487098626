import { GET, POST } from '@/utils/request';
export function campaignList(data) {
  return POST('/manage/ad/list', data);
}
// 定时开启
export function settingAdScheduleOpenTaskMobile(data) {
  let newData = {
    ...data,
    clientType: 'mobile',
  };
  return POST('/manage/ads/settingAdScheduleOpenTask', newData);
}
// 定时取消
export function delAdScheduleOpenTaskMobile(data) {
  let newData = {
    ...data,
    clientType: 'mobile',
  };
  return POST('/manage/ads/delAdScheduleOpenTask', newData);
}
// 定时开启预算
export function settingAdScheduleUpdateBudgetTaskMobile(data) {
  let newData = {
    ...data,
    clientType: 'mobile',
  };
  return POST('/manage/ads/settingAdScheduleUpdateBudgetTask', newData);
}
// 定时取消预算
export function delAdScheduleUpdateBudgetTaskMobile(data) {
  let newData = {
    ...data,
    clientType: 'mobile',
  };
  return POST('/manage/ads/delAdScheduleUpdateBudgetTask', newData);
}
// 获取常量
export function adConstsMap(data) {
  return GET('/manage/ads/adConstsMap', data);
}
// 开启任务
export function spendJob(data) {
  return GET('/report/api/mobile/spend/job', data);
}
// 任务详情
export function jobDetail(data) {
  return GET('/job/jobDetail', data);
}
// 修改广告系列广告组状态
export function mutateStatus(data) {
  return POST('/manage/mutateStatus', data);
}
// 修改广告系列广告组预算竞价
export function mutateBudget(data) {
  return POST('/manage/mutateBudget', data);
}
// 复制广告组到当前广告系列
export function copyAdset(data, platform) {
  return POST('/ads/copyAdset', data, process.env.VUE_APP_MOBILE_API, { platform: platform });
}

// 复制广告系列
export function copyAdAsync(data) {
  return POST('/manage/ad/copyAdAsync', data);
}

// 定时改出价
export function settingAdScheduleUpdateBidTasks(data) {
  return POST('/manage/ads/settingAdScheduleUpdateBidTasks', data);
}

// 取消定时改出价
export function delUpdateBidTask(data) {
  return POST('/manage/ads/delUpdateBidTask', data);
}

// 改出价 manage/mutateBatchBid
export function mutateBatchBid(data) {
  return POST('/manage/mutateBatchBid', data);
}

// 修改名称
export function mutateName(data) {
  return POST('/manage/mutateName', data);
}
// 广告日志操作分页查询
export function selectLogByPage(data) {
    return GET('/log/selectLogByPage',data)}