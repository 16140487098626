import { campaignList, mutateStatus, adConstsMap } from '@/api/campaign';
import { Notify } from 'vant';
let currentPlatform = localStorage.getItem('platform');
try {
  if (currentPlatform && typeof currentPlatform === 'string') {
    currentPlatform = JSON.parse(currentPlatform);
  } else {
    currentPlatform = '';
  }
} catch (e) {
  //TODO handle the exception
  currentPlatform = '';
}
const statusList = [
  { key: 'ACTIVE', val: 1 },
  { key: 'PAUSED', val: 2 },
  { key: 'DELETED', val: 3 },
];

function getIndex(key) {
  return statusList.filter((v) => v.key == key).length ? statusList.filter((v) => v.key == key)[0][`val`] : 100;
}

const editMethods = {
  data() {
    return {
      currentPlatform: currentPlatform || 'facebook',
      keyword: '',
      listParams: {},
      initData: {}, //常量
      list: [],
      currentPage: 0,
      pageSize: 10,
      finished: false,
      loading: false,
      refreshing: false,
      dateRange: null,
    };
  },
  mounted() {
    this.getInitData();
  },
  methods: {
    async getInitData() {
      await adConstsMap().then((res) => {
        this.initData = res.data;
      });
    },
    // 选择币种，选择日期
    setParams(key, val, range) {
      this.params[key] = val;
      this.dateRange = range;
      // this.finished = false;
      this.list = [];
      this.currentPage = 0;
      this.getList();
    },
    // 下拉刷新
    onRefresh() {
      // this.finished = false;
      this.currentPage = 0;
      this.loading = true;
      this.getList();
    },
    // 获取列表
    getDefaultList(params) {
      if (this.refreshing) {
        this.list = [];
        this.refreshing = false;
      }
      this.listParams = { ...params };
      this.$showLoading();
      campaignList(params)
        .then((res) => {
          this.$hideLoading();
          this.loading = false;
          if (res.code == 0) {
            let list = this.list.concat(res.data?.adReportList || []);

            list.map((v) => {
              v.statusIndex = getIndex(v.ad.adGroupStatus);
            });
            let list1 = list
              .filter((v) => v.ad.adGroupStatus == 'ACTIVE')
              .sort((a, b) => b.statistics.spend - a.statistics.spend);
            let list2 = list
              .filter((v) => v.ad.adGroupStatus == 'PAUSED')
              .sort((a, b) => b.statistics.spend - a.statistics.spend);
            let list3 = list
              .filter((v) => v.ad.adGroupStatus == 'DELETED')
              .sort((a, b) => b.statistics.spend - a.statistics.spend);
            let list4 = list
              .filter(
                (v) =>
                  v.ad.adGroupStatus != 'ACTIVE' && v.ad.adGroupStatus != 'PAUSED' && v.ad.adGroupStatus != 'DELETED',
              )
              .sort((a, b) => b.statistics.spend - a.statistics.spend);
            this.list = list1.concat(list2).concat(list3).concat(list4);

            if (!res.data || res.data.adReportList.length < (this.params.gkPage?.size || 10)) {
              this.finished = true;
            } else {
              this.currentPage += 1;
              this.finished = false;
            }
          }
        })
        .catch(() => {
          this.$hideLoading();
        });
    },
    // 选择平台
    selectPlat(v) {
      localStorage.setItem('platform', v ? JSON.stringify(v) : 'null');
      this.currentPage = 0;
      // this.finished = false;
      this.list = [];
      this.getList();
    },
    // 修改状态
    editStatus(params, id, idKey, key, val) {
      this.$showLoading();
      mutateStatus(params)
        .then((res) => {
          this.$hideLoading();
          if (res.code == 0) {
            Notify({ type: 'success', message: '修改成功' });
            this.setData(id, idKey, key, val);
          } else {
            Notify({ type: 'danger', message: res.comment });
          }
        })
        .catch(() => {
          this.$hideLoading();
        });
    },
    setData(id, idkey, key, val) {
      // console.log(this.list[index],key,val);
      let index = this.list.findIndex((v) => v.ad[idkey] == id);
      let msg = this.list[index];
      msg.ad[key] = val;
      this.$set(this.list, index, msg);
      // console.log(msg);
    },
    emitOpenSchedule(index, item) {
      console.log('set list', index);
      this.$set(this.list, index, { ...item });
    },
  },
};
export default editMethods;
